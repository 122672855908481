#offer {
  position: relative;
  width: 100%;
  height: 100vh;

  .wrap {
    overflow: hidden;
    height: 100%;

    img {
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100vh;
    }
  }

  .title {
    position: absolute;
    z-index: 1;
    bottom: -1px;
    width: 100%;
  }
}
