/* ------ Upper Arrow ------ */
#upper {
  display: none;
  bottom: 180px;
  right: 10px;

  .circle-img {
    background-image: url(../Fixed-btns/arrow-up.png);
  }
}

#call {
  bottom: 40px;
  right: 10px;

  .circle-img {
    background-image: url(../Fixed-btns/call.png);
  }
}

.fixed-btn {
  position: fixed;
  z-index: 777;
  cursor: pointer;
  opacity: 0.7;

  &:hover {
    opacity: 1;
  }

  .circle-max {
    position: absolute;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    border: 2px solid $color-5;
    width: 150px;
    height: 150px;
    bottom: -22px;
    right: 13px;
    -webkit-animation: circle-anim 2.4s infinite ease-in-out !important;
    -moz-animation: circle-anim 2.4s infinite ease-in-out !important;
    -ms-animation: circle-anim 2.4s infinite ease-in-out !important;
    -o-animation: circle-anim 2.4s infinite ease-in-out !important;
    animation: circle-anim 2.4s infinite ease-in-out !important;
    @include border-radius(50%);
    @include transition(0.5s);
    opacity: 0.7;
  }

  .circle-min {
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    background-color: $color-5;
    width: 100px;
    height: 100px;
    bottom: 0px;
    right: 35px;
    position: absolute;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-radius: 100%;
    border: 5px solid transparent;
    -webkit-animation: circle-fill-anim 2.3s infinite ease-in-out;
    -moz-animation: circle-fill-anim 2.3s infinite ease-in-out;
    -ms-animation: circle-fill-anim 2.3s infinite ease-in-out;
    -o-animation: circle-fill-anim 2.3s infinite ease-in-out;
    animation: circle-fill-anim 2.3s infinite ease-in-out;
    @include transition(0.5s);
  }

  .circle-center {
    position: absolute;
    box-sizing: content-box;
    -webkit-box-sizing: content-box;
    border: 5px solid rgba(9, 75, 77, 0.3);
    background-color: $color-3;
    width: 68px;
    height: 68px;
    bottom: 16px;
    right: 51px;
    @include border-radius(50%);

    .circle-img {
      margin: 12px auto 0;
      box-sizing: content-box;
      -webkit-box-sizing: content-box;
      background-size: cover;
      width: 42px;
      height: 42px;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }

  .fixed-btn-tip {
    position: absolute;
    background-color: $color-3;
    box-shadow: 0 0 0 6px rgba(153, 153, 153, 0.3), 0 10px 30px rgba(0, 0, 0, 0.3);
    width: 190px;
    height: 50px;
    top: 0;
    right: 0;
    margin-top: -75px;
    margin-right: 160px;
    opacity: 0;
    z-index: 888;
    padding-top: 7px;
    @include border-radius(15px);
    @include transition(0.5s);

    a {
      display: block;
      margin-top: 6px;
      color: $color-5;
      font-size: 17px;
      text-align: center;
      font-weight: bold;
    }

    &:hover a {
      color: $black;
    }
  }

  &:hover {
    .fixed-btn-tip {
      opacity: 1;
    }

    .circle-img {
      animation-name: tossing;
      -webkit-animation-name: tossing;
      animation-duration: 1.5s;
      -webkit-animation-duration: 1.5s;
      animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
    }
  }
}

// Animation
@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 1;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }

  50% {
    transform: scale(1);
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
  }

  100% {
    transform: scale(0.9);
    opacity: 1;
    -webkit-transform: scale(0.9);
    -moz-transform: scale(0.9);
    -ms-transform: scale(0.9);
    -o-transform: scale(0.9);
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(0.95);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(0.95);
    opacity: 1;
  }
}

@keyframes tossing {
  0% {
    transform: rotate(-8deg);
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
  }

  50% {
    transform: rotate(8deg);
    -webkit-transform: rotate(8deg);
    -moz-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    -o-transform: rotate(8deg);
  }

  100% {
    transform: rotate(-8deg);
    -webkit-transform: rotate(-8deg);
    -moz-transform: rotate(-8deg);
    -ms-transform: rotate(-8deg);
    -o-transform: rotate(-8deg);
  }
}

@-webkit-keyframes tossing {
  0% {
    -webkit-transform: rotate(-8deg);
  }

  50% {
    -webkit-transform: rotate(8deg);
  }

  100% {
    -webkit-transform: rotate(-8deg);
  }
}

@-moz-keyframes circle-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
    -moz-opacity: 0.1;
    -webkit-opacity: 0.1;
    -o-opacity: 0.1;
  }

  30% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.75;
    -moz-opacity: 0.75;
    -webkit-opacity: 0.75;
    -o-opacity: 0.75;
  }

  100% {
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.1;
    -moz-opacity: 0.1;
    -webkit-opacity: 0.1;
    -o-opacity: 0.1;
  }
}

@-webkit-keyframes circle-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -webkit-opacity: 0.1;
  }

  30% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    -webkit-opacity: 0.75;
  }

  100% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -webkit-opacity: 0.1;
  }
}

@keyframes circle-anim {
  0% {
    transform: rotate(0deg) scale(0.5) skew(1deg);
    opacity: 0.1;
    -webkit-transform: rotate(0deg) scale(0.5) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.5) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.5) skew(1deg);
    -o-transform: rotate(0deg) scale(0.5) skew(1deg);
  }

  30% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.75;
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.7) skew(1deg);
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
  }

  100% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.1;
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    -ms-transform: rotate(0deg) scale(1) skew(1deg);
    -o-transform: rotate(0deg) scale(1) skew(1deg);
  }
}

@-moz-keyframes circle-fill-anim {
  0% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.4;
  }

  50% {
    -moz-transform: rotate(0deg) -moz-scale(1) skew(1deg);
    opacity: 0.4;
  }

  100% {
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.4;
  }
}

@-webkit-keyframes circle-fill-anim {
  0% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.4;
  }

  50% {
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.4;
  }

  100% {
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.4;
  }
}

@keyframes circle-fill-anim {
  0% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.4;
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.7) skew(1deg);
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
  }

  50% {
    transform: rotate(0deg) scale(1) skew(1deg);
    opacity: 0.4;
    -webkit-transform: rotate(0deg) scale(1) skew(1deg);
    -moz-transform: rotate(0deg) scale(1) skew(1deg);
    -ms-transform: rotate(0deg) scale(1) skew(1deg);
    -o-transform: rotate(0deg) scale(1) skew(1deg);
  }

  100% {
    transform: rotate(0deg) scale(0.7) skew(1deg);
    opacity: 0.4;
    -webkit-transform: rotate(0deg) scale(0.7) skew(1deg);
    -moz-transform: rotate(0deg) scale(0.7) skew(1deg);
    -ms-transform: rotate(0deg) scale(0.7) skew(1deg);
    -o-transform: rotate(0deg) scale(0.7) skew(1deg);
  }
}

@media screen and (max-width: 576px) {
  .container {
    width: 340px;
  }

  #upper {
    right: -40px;
    bottom: 130px;
  }

  #call {
    right: -40px;
    bottom: 0;
  }
}
