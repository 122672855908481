#types-of-surfing_tabs {
  margin-top: 100px;

  .tabs-nav {
    justify-content: space-between;
  }

  .tab-nav {
    width: 25%;
    list-style-type: none;
    font-size: 20px;
    margin: 0 5px;
    padding: 15px 0;
    text-align: center;
    text-decoration: none;
    color: $white;
    cursor: pointer;
    background-color: $color-2;
    @include border-radius(50px);

    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-child(5) {
      margin-right: 0;
    }

    &:hover {
      background-color: $color-5;
    }

    &.active {
      background: $color-3;
      color: $color-5;
      font-weight: bold;
      cursor: default;
    }
  }

  .tab-content {
    margin-top: 35px;
    font-size: 20px;
    background-color: $color-2;
    @include border-radius(25px);
  }

  .tab-pane {
    display: none;
    padding: 50px;

    &.active {
      display: flex;
    }

    .wrap {
      justify-content: space-between;

      h2 {
        @include textBlue;
      }

      .left {
        width: 55%;
        margin: auto 0;

        p {
          letter-spacing: 0.8px;
          line-height: 1.7;

          i {
            @include textBlue;
          }
        }
      }

      .right {
        width: 40%;
        margin: auto;
        margin-right: 0;

        .box {
          width: 100%;
          height: 300px;
          overflow: hidden;
          @include border-radius(25px);

          img {
            width: 100%;
            cursor: pointer;

            &:hover {
              filter: none;
              -webkit-filter: none;
            }
          }
        }
      }
    }
  }
}
