#come-with-us {
  .wrap {
    .left {
      position: relative;

      h2 {
        line-height: 1;
        margin-top: -170px;
      }

      .mess {
        margin-top: 30px;
        width: 200px;
        padding-left: 0;
        justify-content: space-between;

        a {
          color: $white;

          img {
            display: block;
            height: 45px;
            width: 45px;
          }

          &:hover img {
            filter: none;
            -webkit-filter: none;
          }
        }
      }
    }
  }
}
