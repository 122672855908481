#subscriptions {
  margin-top: 120px;
  margin-bottom: 50px;

  .title {
    margin: auto;
    text-align: center;
    width: 650px;

    .wrapper::before {
      left: 50%;
      margin-left: -90px;
    }
  }

  .wrap {
    margin-top: 70px;
    justify-content: space-between;

    .item {
      width: 31%;
      height: 350px;
      overflow: hidden;
      @include border-radius(25px);

      .df {
        padding: 20px 35px;
        justify-content: space-between;

        span {
          font-weight: 700;
          font-size: 1.4rem;
          color: $white;
          line-height: 1.75;
        }
      }

      .box {
        padding-top: 40px;
        background-color: $color-2;
        height: 100%;
        text-align: center;

        .btn {
          margin: 20px auto 25px;
          font-size: 1.2rem;
        }

        p {
          font-size: 2rem;
          font-weight: 700;
        }

        p,
        span {
          display: block;
          color: $white;
        }

        span {
          font-size: 1.3rem;
          opacity: 0.5;
        }
      }

      &:nth-child(1) {
        .df {
          background-color: $color-1;
        }

        .btn {
          background: linear-gradient(270deg, #9c9c9c -8.89%, #cacaca 109.17%);

          &:hover {
            background: linear-gradient(270deg, #6d6d6d -8.89%, #747474 109.17%);
          }
        }
      }

      &:nth-child(2) {
        .df {
          background-color: $color-4;
        }

        .btn {
          background: linear-gradient(270deg, #024788 -8.89%, #206ad0 109.17%);

          &:hover {
            background: linear-gradient(270deg, #133b61 -8.89%, #083258 109.17%);
          }
        }
      }

      &:nth-child(3) {
        .df {
          background: linear-gradient(270deg, #07948a -8.89%, $color-3 109.17%);
        }
      }
    }
  }

  .wrap-down {
    margin-top: 40px;
    padding: 35px;
    background-color: $color-2;
    @include border-radius(25px);

    .left {
      width: 65%;

      p {
        margin-top: 25px;
      }
    }

    .right {
      width: 20%;
      margin: auto;
      text-align: center;

      span {
        font-size: 1.6rem;
        font-weight: 700;
        color: $white;
      }

      .btn {
        margin-top: 20px;
      }
    }
  }
}
