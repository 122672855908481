footer {
  padding: 20px 0;

  .container {
    justify-content: space-between;

    p {
      opacity: 0.3;
    }

    a {
      color: $white;
      opacity: 0.3;

      &:hover {
        opacity: 1;
      }
    }
  }
}

// Modal Window
.show {
  display: block !important;
}

.window {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
}

.modal {
  .a-center {
    top: 0;
    @include transform(-50%, 55%);
  }

  &-box {
    width: 520px;
    height: 380px;
    background-color: $color-2;
    @include border-radius(25px);

    form {
      position: relative;
      width: 100%;
      margin: 30px auto 0;
      padding: 0 100px;

      h4 {
        color: $white;
        text-align: center;
        margin-bottom: 25px;
      }

      label {
        position: relative;

        input {
          margin: 15px 0;
          width: 100%;
          height: 55px;
          border: 0;
          font-size: 1rem;
          text-align: left;
          padding-left: 21px;
          color: $white;
          background-color: $color-2;
          border: 1px solid rgba(255, 255, 255, 0.2);
          @include border-radius(25px);
        }

        .label-text {
          position: absolute;
          top: -1px;
          left: 21px;
          color: $white;
          font-size: 1rem;
          @include transition(0.2s);
        }
      }

      .label input:focus {
        background-color: $black;
      }

      label input:focus + .label-text {
        font-size: 0.55rem;
        margin-top: -10px;
        color: $color-3;
      }

      label input:valid + .label-text {
        font-size: 0.55rem;
        margin-top: -10px;
        color: $color-3;
      }

      button {
        margin: 20px auto 0;
        width: 100%;
        border: none;
      }
    }
  }
}

.modal-close {
  position: absolute;
  top: 25px;
  right: 25px;
  background-color: transparent;
  border: none;
  @include border-radius(50%);
  opacity: 0.5;

  img {
    -o-object-fit: cover;
    object-fit: cover;
    width: auto;
    height: 18px;
  }

  &:hover {
    opacity: 1;
  }
}

.popup-success {
  z-index: 2;
}

// Media Queries
$media-color: 0;
//====================================================================
/* 1200-1300px */
//====================================================================
@media screen and (max-width: 1300px) {
  .container {
    background-color: rgba(73, 0, 106, $media-color);
    width: 1180px;
  }
}
//====================================================================
/* 992-1200px */
//====================================================================
@media screen and (max-width: 1200px) {
  .container {
    background-color: rgba(255, 0, 0, $media-color);
    width: 940px;
  }
}
//====================================================================
/* 768-992px */
//====================================================================
@media screen and (max-width: 992px) {
  .container {
    background-color: rgba(0, 0, 255, $media-color);
    width: 720px;
  }
}
//====================================================================
/* 576-768px */
//====================================================================
@media screen and (max-width: 768px) {
  .container {
    background-color: rgba(0, 250, 0, $media-color);
    width: 540px;
  }
}
//====================================================================
/* 320-576px */
//====================================================================
@media screen and (max-width: 576px) {
  .container {
    background-color: rgba(0, 217, 255, $media-color);
    width: 320px;
  }

  .modal-box {
    width: 340px;
    height: 365px;
    form {
      padding: 0 30px;
    }
  }
}
