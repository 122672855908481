#header {
  position: relative;
  z-index: 777;

  .wrap {
    width: 100%;
    position: fixed;
    padding-top: 10px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);

    .container {
      justify-content: space-between;
      align-items: center;

      .logo {
        img {
          height: 65px;
        }
      }

      .btns {
        width: 280px;
        background: linear-gradient(270deg, #05867e -8.89%, $color-3 109.17%);
        @include border-radius(50px);
        padding: 3px;
        padding-left: 15px;
        padding-right: 4px;

        .btn {
          border: dotted 1px $white;
          background: transparent;

          &:hover {
            background: linear-gradient(270deg, #0c5758 -8.89%, #0b6f70 109.17%);
            border: none;
          }
        }
      }
    }
  }

  .header-bg {
    padding: 10px;
    background-color: rgba(11, 145, 185, 0.35);
    @include transition(0.5s);
  }
}

.btn {
  @include btn;
}
