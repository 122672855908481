#lang {
  position: relative;
  width: 65px;
  height: 40px;
  margin: auto;

  &[data-state="active"] span::before {
    transform: translate(-3px, -50%) rotate(-45deg);
  }
  &[data-state="active"] span::after {
    transform: translate(3px, -50%) rotate(45deg);
  }
  &[data-state="active"] .lang-wrap {
    opacity: 1;
  }
  &[data-state="active"] .select-label + .select-input + .select-label {
    max-height: 40px;
    border-top-width: 1px;
  }

  span {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    cursor: pointer;
    text-transform: uppercase;
    color: $white;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 47%;
      right: 16px;
      display: block;
      width: 10px;
      height: 2px;
      background-color: $white;
      transform: translate(-3px, -50%) rotate(45deg);
    }
    &::after {
      transform: translate(3px, -50%) rotate(-45deg);
    }
    &:hover {
      color: $color-5;
    }
    &:hover::before,
    &:hover::after {
      background-color: $color-5;
    }
  }

  .lang-wrap {
    position: absolute;
    z-index: 7;
    top: 40px;
    left: 3px;
    display: flex;
    flex-direction: column;
    width: calc(100% - 6px);
    background-color: $color-3;
    transition: all 0.3s ease-out;
    overflow: hidden;
    @include border-radius(10px);
    opacity: 0;

    .select-input {
      display: none;

      &:checked + label {
        background-color: #dedede;
      }

      &:disabled + label {
        opacity: 0.6;
        pointer-events: none;
      }
    }

    .select-label {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      max-height: 0;
      padding: 0 16px;
      transition: all 0.2s ease-out;
      text-transform: uppercase;
      cursor: pointer;
      overflow: hidden;

      &:hover {
        background-color: $color-5;
        color: #ffffff;
      }
    }
    .select-label + input + .select-label {
      border-top: 0 solid #c7ccd160;
    }
  }
}
