@import "./fonts";
@import "./vars";
@import "./swiper";

html {
  max-width: 100%;
  width: 100%;
  font-size: 18px;
}

body {
  max-width: 100%;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  font-family: $font;
  font-weight: 400;
  background-color: $black;
  overflow-x: hidden;
}

.container {
  margin: 0 auto;
  width: 1280px;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 4rem;
  font-weight: 900;
  line-height: 1.1;
  color: $white;
}

h3 {
  font-size: 2.5rem;
  font-weight: 400;
  color: $white;
}

h4 {
  font-size: 1.5rem;
  font-weight: 400;
  color: $black;
}

p {
  font-size: 1rem;
  color: $white;
}

// Custom styles App
.btn {
  @include btn;
}

.words {
  @include words;
}

.left,
.right {
  width: 600px;
  margin: auto;
}

.left {
  margin-left: 0;
}

.right {
  margin-right: 0;
}

.wrapper {
  position: relative;
  margin-top: 120px;
  padding-left: 50px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -65px;
    left: 0;
    width: 180px;
    height: 8px;
    background: linear-gradient(270deg, #206ad0 -8.89%, #09c5c9 109.17%);
    @include border-radius(5px);
  }

  p {
    margin: 30px 0;
  }
}

@import "../App/Header/header";
@import "../App/Main/Offer/offer";
@import "../App/Main/Your-Space/your-space";
@import "../App/Main/First-Task/first-task";
@import "../App/Main/Types-of-Surfing/types-of-surfing";
@import "../App/Main/Subscriptions/subscriptions";
@import "../App/Main/Buy-Forgive/buy-forgive";
@import "../App/Main/Tours/tours";
@import "../App/Main/Come-with-us/come-with-us";
@import "../App/Main/Contacts/contacts";
@import "../App/Footer/footer";

@import "./components/Lang/lang";
@import "./components/Lines/lines";
@import "./components/Lines-Slider/slider";
@import "./components/Tabs/tabs";
@import "./components/Fixed-btns/fixed-btns";

@import "../App/media";
