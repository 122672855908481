#contacts {
  position: relative;
  margin-top: 0;

  .words {
    line-height: 0.8;
  }
  .map {
    position: relative;

    img {
      width: 100%;
    }
  }

  .container {
    top: 63%;

    .box {
      padding: 40px;
      width: 350px;
      height: 260px;
      background-color: $color-3;

      @include border-radius(25px);

      a {
        margin: 30px 0;
        position: relative;

        img {
          width: 40px;
          height: 40px;
        }

        &:hover span {
          color: $white;
        }

        &:nth-child(1) {
          margin-top: 0;
        }

        span {
          position: absolute;
          top: 50%;
          left: 60px;
          color: $black;
          @include transform(0, -50%);
        }
      }
    }
  }
}
