// Media Queries
$media-color: 0;
//====================================================================
/* 1300-1600px */
//====================================================================
@media screen and (max-width: 1600px) {
  .words {
    font-size: 14rem;
  }

  .on-wave,
  .sun,
  .hot {
    display: none !important;
  }
}

//====================================================================
/* 1200-1300px */
//====================================================================
@media screen and (max-width: 1300px) {
  .container {
    background-color: rgba(73, 0, 106, $media-color);
    width: 1180px;
  }

  html {
    font-size: 17px;
  }

  .left,
  .right {
    width: 550px;
  }

  #tours .items .wrap .item {
    width: 370px;
  }

  #subscriptions .wrap .item {
    width: 370px;
  }
}
//====================================================================
/* 992-1200px */
//====================================================================
@media screen and (max-width: 1200px) {
  .container {
    background-color: rgba(255, 0, 0, $media-color);
    width: 940px;
  }

  h2 {
    font-size: 3.3rem;
  }

  .words {
    font-size: 11.5rem;
  }

  .left,
  .right {
    width: 450px;
  }

  #your-space .wrap .right .wrap {
    width: 100%;
    height: 100%;

    img {
      height: auto;
    }
  }

  #first-task .wrap .right .price-list {
    .box ul {
      height: auto;
      margin-bottom: 30px;
    }

    .box-down {
      justify-content: center;
      flex-direction: column;
      text-align: center;

      div {
        margin-top: 15px;

        .btn {
          width: 100%;
        }
      }
    }

    .box p {
      margin-top: 13px;
    }
  }

  #tours .items .wrap .item {
    width: 300px;
    height: 500px;

    .df {
      text-align: center;
      flex-direction: column;
      padding: 10px;
    }
  }

  .prev,
  .next {
    top: 230px;
  }

  #subscriptions {
    .wrap .item {
      width: 300px;

      .df {
        flex-direction: column;
        text-align: center;
        padding: 10px;
      }
    }

    .wrap-down {
      .left {
        width: 65%;
      }

      .right {
        width: 30%;
      }
    }
  }

  #buy-forgive {
    margin-top: 120px;
  }
}
//====================================================================
/* 768-992px */
//====================================================================
@media screen and (max-width: 992px) {
  .container {
    background-color: rgba(0, 0, 255, $media-color);
    width: 720px;
  }

  html {
    font-size: 17px;
  }

  h2 {
    font-size: 3rem;
  }

  .left,
  .right {
    width: 350px;
  }

  .title {
    height: 50%;

    .words {
      font-size: 8.5rem;
      height: 100%;
    }
  }

  .wrapper {
    padding-left: 0;
  }

  #types-of-surfing #types-of-surfing_tabs .tab-nav {
    font-size: 0.85rem;
  }

  #types-of-surfing #types-of-surfing_tabs .tab-pane .wrap {
    flex-direction: column;

    .left {
      width: 100%;
    }

    .right {
      width: 100%;

      .box {
        height: 380px;
      }
    }
  }

  #subscriptions {
    .wrap .item {
      width: 100%;
      margin: 20px auto;

      .df {
        flex-direction: row;
        padding: 20px 50px;

        h4 {
          font-size: 1.7rem;
        }

        span {
          font-size: 1.5rem;
        }
      }
    }

    .wrap-down {
      flex-direction: column;

      .left,
      .right {
        width: 100%;
      }

      .right {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;

        span {
          display: block;
          margin: auto 0;
        }

        .btn {
          margin-top: 0;
        }
      }
    }
  }

  #tours .items .wrap .item {
    width: 100%;
    height: 480px;
    margin: 25px auto;

    .df {
      flex-direction: row;
      padding: 20px 50px;

      h4 {
        font-size: 1.7rem;
      }

      span {
        font-size: 1.5rem;
      }
    }
  }
}
//====================================================================
/* 576-768px */
//====================================================================
@media screen and (max-width: 768px) {
  .container {
    background-color: rgba(0, 250, 0, $media-color);
    width: 540px;
  }

  h4 {
    font-size: 1.9rem;
  }

  #header .wrap .container .df {
    width: auto;

    .tel {
      display: none;
    }
  }

  #offer {
    overflow: hidden;
  }

  .left,
  .right {
    width: 100%;
  }

  .words {
    font-size: 7rem;
  }

  .lines {
    overflow-x: hidden;
    width: auto;
    height: 300px;

    img {
      -o-object-fit: cover;
      object-fit: cover;
      max-width: 200%;
      width: auto;
      height: 100%;
      left: 50%;
      @include transform(-25%, 0);
    }
  }

  #offer {
    overflow-x: hidden;

    img {
      width: auto;
      height: 100vh;
      margin-left: 0;
    }
  }

  #your-space .wrap .right .wrap img {
    width: 100%;
  }

  #first-task {
    .price-list {
      margin-top: 20px;
    }
  }

  #types-of-surfing #slider .slide {
    flex: 1;

    h3 {
      font-size: 1.4rem;
    }
  }

  #types-of-surfing {
    #types-of-surfing_tabs {
      margin-top: 50px;

      .tabs-nav {
        flex-wrap: wrap;
        justify-content: space-around;

        .tab-nav {
          margin: 10px auto;
          width: 45%;
          font-size: 1rem;
        }
      }

      .tab-pane {
        padding: 25px;

        .wrap .right .box {
          height: auto;
        }
      }
    }
  }

  #your-space .wrap .right {
    margin-top: 20px;

    .wrap img {
      height: 500px;
    }
  }

  #subscriptions {
    margin-top: 100px;

    .title {
      width: 100%;
    }

    .wrap {
      margin-top: 0;

      .item {
        height: 370px;

        .df span {
          font-size: 1.5rem;
          line-height: 1.5;
        }
      }
    }

    .wrap-down {
      margin-top: 15px;
    }
  }

  #come-with-us {
    .right {
      margin-top: 100px;
    }
  }

  #contacts {
    .words {
      padding-bottom: 30px;
    }
    .container .box {
      padding: 15px;
      top: 50%;
      width: 280px;
      height: 200px;

      a {
        margin: 20px 0;

        span {
          left: 55px;
        }
      }
    }
  }

  .prev,
  .next {
    top: 100px;
  }

  footer {
    padding: 30px 0;

    .container {
      p,
      a {
        width: 100%;
      }
    }
  }
}
//====================================================================
/* 340-576px */
//====================================================================
@media screen and (max-width: 576px) {
  .container {
    background-color: rgba(0, 217, 255, $media-color);
    width: 340px;
  }

  h2 {
    font-size: 2.8rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.3rem;
  }

  #header .wrap .container {
    position: relative;

    .logo {
      position: absolute;
      top: 0;

      img {
        height: 50px;
      }
    }

    .btns {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 260px;

      select {
        margin-right: 8px;
      }

      .btn {
        height: 45px;
      }
    }
  }

  #offer {
    position: relative;
    width: 100%;
    height: 100%;

    .offer-bg {
      display: none;
    }

    .offer-mob {
      display: block !important;
      margin-left: 0;
    }

    .title {
      width: 100%;
      margin-left: 0;

      .words {
        font-size: 9rem;

        &::after {
          bottom: 0;
        }
      }
    }
  }

  #your-space {
    padding-top: 10px;
  }

  #first-task {
    padding-top: 35px;
    padding-bottom: 90px;

    .wrap .right {
      .price-list .box {
        padding: 20px;
      }

      .price-list .box p:nth-child(2) {
        margin-top: 25px;
      }

      .price-min {
        padding: 20px;
      }

      .price-list .box-down p {
        font-size: 1.8rem;
      }
    }
  }

  #types-of-surfing {
    #title {
      text-align: left;
      max-width: 100%;

      .wrapper::before {
        left: 0;
        margin-left: 0;
      }
    }

    #slider {
      height: 70vh;

      .slide {
        height: 65vh;
        flex: 1.5;
        margin: 3px;

        h3 {
          bottom: 10px;
          left: 10px;
          font-size: 0.8rem;
          padding: 5px 10px;
        }
      }
    }

    h2 {
      font-size: 2.2rem;
    }
  }

  #tours .items .wrap .item .df {
    padding: 20px;
  }

  #subscriptions {
    .title {
      text-align: left;

      .wrapper::before {
        left: 0;
        margin-left: 0;
      }
    }

    .wrap .item {
      height: 330px;

      .df {
        padding: 20px;
        flex-direction: row;

        span {
          font-size: 1.35rem;
          line-height: 1.53;
        }

        h4 {
          font-size: 1.4rem;
        }
      }
    }

    .wrap-down {
      padding: 30px 20px;
      text-align: center;

      .right {
        flex-direction: column;

        .btn {
          margin: 20px auto;
        }
      }
    }
  }

  #buy-forgive {
    margin-top: 100px;
  }

  #come-with-us {
    margin-top: 100px;

    .wrap .left .mess {
      width: 175px;
    }

    .right {
      margin-top: 75px;
    }
  }

  .prev,
  .next {
    top: 125px;
  }

  #contacts {
    .map {
      position: relative;
      z-index: 0;
      margin-top: 90px;
      height: 380px;
      overflow-x: hidden;

      img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }

    .words {
      font-size: 4rem;
    }

    .container {
      top: 35%;
      padding-left: 8%;

      .box {
        img {
          margin-left: 0;
        }
      }
    }
  }
}
