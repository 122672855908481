@import "./App/reset";

// Custom styles
.df {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}

.fww {
  -o-flex-wrap: wrap;
  flex-wrap: wrap;
}

.a-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.bw {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
  filter: gray;
  transition: all 1s;
}

.red {
  border: 1px solid red;
}

.green {
  border: 1px solid green;
}

.blue {
  border: 1px solid blue;
}

.black {
  border: 1px solid black;
}
