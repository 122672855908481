.lines {
  width: 100%;
  max-height: 490px;

  img {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}
