#tours {
  padding-bottom: 160px;

  .wrap {
    .right {
      .wrap {
        width: 100%;
        overflow: hidden;
        @include border-radius(25px);

        img {
          width: 100%;
        }
      }
    }
  }

  .items {
    .wrap {
      margin-top: 70px;
      justify-content: space-between;

      .item {
        width: 400px;
        height: 480px;
        overflow: hidden;
        @include border-radius(25px);

        .df {
          padding: 20px 35px;
          justify-content: space-between;

          span {
            font-weight: 700;
            font-size: 1.4rem;
            color: $white;
            line-height: 1.75;
          }
        }

        .box {
          padding-top: 40px;
          background-color: $color-2;
          height: 100%;
          text-align: center;

          ul {
            width: 210px;
            margin: auto;
            text-align: left;

            li {
              position: relative;
              color: $white;
              margin: 15px 0;
              padding-left: 30px;
              font-size: 1.1rem;

              &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 10px;
                background-color: $color-3;
                width: 8px;
                height: 8px;
                @include border-radius(50%);
              }
            }
          }

          .btn {
            margin: 20px auto 0;
            font-size: 1.2rem;
          }
        }

        &:nth-child(1) {
          .df {
            background-color: $color-1;
          }

          .btn {
            background: linear-gradient(270deg, #9c9c9c -8.89%, #cacaca 109.17%);

            &:hover {
              background: linear-gradient(270deg, #6d6d6d -8.89%, #747474 109.17%);
            }
          }
        }

        &:nth-child(2) {
          .df {
            background-color: $color-4;
          }

          .btn {
            background: linear-gradient(270deg, #024788 -8.89%, #206ad0 109.17%);

            &:hover {
              background: linear-gradient(270deg, #133b61 -8.89%, #083258 109.17%);
            }
          }
        }

        &:nth-child(3) {
          .df {
            background: linear-gradient(270deg, #07948a -8.89%, $color-3 109.17%);
          }
        }
      }
    }
  }
}
