#buy-forgive {
  .container {
    position: relative;

    .hot {
      position: absolute;
      bottom: -320px;
      right: -100px;
    }
  }

  .right {
    .wrap {
      overflow: hidden;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
