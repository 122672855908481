#slider {
  overflow: hidden;
  margin: 0;
  margin-top: 100px;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .slide {
    position: relative;
    height: 80vh;
    margin: 7px;
    cursor: pointer;
    flex: 2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    @include border-radius(20px);
    @include transition(500ms);

    h3 {
      position: absolute;
      bottom: 20px;
      left: 20px;
      margin: 0;
      opacity: 0;
      color: $color-3;
      padding: 5px 30px;
      font-size: 2rem;
      background-color: rgba(0, 0, 0, 0.7);
      @include border-radius(50px);
    }

    &:hover {
      filter: none;
      -webkit-filter: none;
    }

    &:nth-child(1) {
      margin-left: 0;
    }

    &:nth-child(5) {
      margin-right: 0;
    }
  }

  .slide.active {
    flex: 10 !important;

    h3 {
      opacity: 1;
      transition: opacity 0.3s ease-in 0.4s;
      -webkit-transition: opacity 0.3s ease-in 0.4s;
      -moz-transition: opacity 0.3s ease-in 0.4s;
      -ms-transition: opacity 0.3s ease-in 0.4s;
      -o-transition: opacity 0.3s ease-in 0.4s;
    }
  }
}
