/* ------ Add fonts ------ */
// @import url("https://fonts.googleapis.com/css2?family=Alegreya + Sans + SC:wght@400;500;800");

@font-face {
  font-family: "Geometria";
  src: url("./_fonts/Geometria-Bold.eot");
  src: local("Geometria Bold"), local("Geometria-Bold"), url("./_fonts/Geometria-Bold.woff2") format("woff2"),
    url("./_fonts/Geometria-Bold.woff") format("woff"), url("./_fonts/Geometria-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("./_fonts/Geometria-Heavy.eot");
  src: local("Geometria Heavy"), local("Geometria-Heavy"), url("./_fonts/Geometria-Heavy.woff2") format("woff2"),
    url("./_fonts/Geometria-Heavy.woff") format("woff"), url("./_fonts/Geometria-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("./_fonts/Geometria-Medium.eot");
  src: local("Geometria Medium"), local("Geometria-Medium"), url("./_fonts/Geometria-Medium.woff2") format("woff2"),
    url("./_fonts/Geometria-Medium.woff") format("woff"), url("./_fonts/Geometria-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("./_fonts/Geometria-Regular.eot");
  src: local("Geometria Regular"), url("./_fonts/Geometria-Regular.woff2") format("woff2"),
    url("./_fonts/Geometria-Regular.woff") format("woff"), url("./_fonts/Geometria-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("./_fonts/Geometria-Light.eot");
  src: local("Geometria Light"), url("./_fonts/Geometria-Light.woff2") format("woff2"),
    url("./_fonts/Geometria-Light.woff") format("woff"), url("./_fonts/Geometria-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Geometria";
  src: url("./_fonts/Geometria-Thin.eot");
  src: local("Geometria Thin"), url("./_fonts/Geometria-Thin.woff2") format("woff2"),
    url("./_fonts/Geometria-Thin.woff") format("woff"), url("./_fonts/Geometria-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
