.swiper-container {
  .swiper-slide {
    color: $white;

    .img-box {
      overflow: hidden;
      width: 600px;
      height: 400px;
      @include border-radius(25px);

      img {
        @include img;
      }
    }
  }

  .swiper-pagination {
    .swiper-pagination-bullet {
      background-color: $color-3;
      margin: 0 10px !important;
      width: 6px;
      height: 6px;
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  color: $color-3;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  @include border-radius(50%);

  &:hover {
    box-shadow: 0px 0px 3px rgba(1, 230, 199, 1);
    background-color: rgba(1, 230, 199, 0.2);
  }
}

.prev {
  background: url(./_img/prev.png) no-repeat;
  background-size: cover;
  top: 235px;
  left: 0;
}

.next {
  background: url(./_img/next.png) no-repeat;
  background-size: cover;
  top: 235px;
  left: 60px;
}

.slider-upper {
  margin-top: -440px;
  margin-left: -45px;

  .swiper-container {
    padding-bottom: 100px;
  }
}

// Media Queries
$media-color: 0;
//====================================================================
/* 1200-1300px */
//====================================================================
@media screen and (max-width: 1300px) {
  .container {
    background-color: rgba(73, 0, 106, $media-color);
    width: 1180px;
  }

  .swiper-container .swiper-slide .img-box {
    width: 550px;
    height: 370px;
  }

  .slider-upper {
    margin-top: -410px;
    margin-left: -40px;
  }
}
//====================================================================
/* 992-1200px */
//====================================================================
@media screen and (max-width: 1200px) {
  .container {
    background-color: rgba(255, 0, 0, $media-color);
    width: 940px;
  }

  .swiper-container .swiper-slide .img-box {
    width: 450px;
    height: 320px;
  }

  .slider-upper {
    margin-top: -360px;
    margin-left: -40px;
  }

  .prev,
  .next {
    top: 100px;
  }
}
//====================================================================
/* 768-992px */
//====================================================================
@media screen and (max-width: 992px) {
  .container {
    background-color: rgba(0, 0, 255, $media-color);
    width: 720px;
  }

  .swiper-container .swiper-slide .img-box {
    width: 350px;
    height: 270px;
  }

  .slider-upper {
    margin-top: -300px;
    margin-left: -30px;
  }

  .prev,
  .next {
    top: 150px;
  }
}
//====================================================================
/* 576-768px */
//====================================================================
@media screen and (max-width: 768px) {
  .container {
    background-color: rgba(0, 250, 0, $media-color);
    width: 540px;
  }

  .prev,
  .next {
    top: 20px;
    left: auto;
  }

  .prev {
    right: 65px;
  }

  .next {
    right: 0;
  }

  .swiper-container .swiper-slide .img-box {
    width: 480px;
    height: 370px;
    margin-left: auto;
    margin-right: 0;
  }

  .slider-upper {
    margin-top: -420px;
    margin-left: -40px;

    .swiper-container .swiper-slide .img-box {
      margin-left: 40px !important;
    }
  }
}
//====================================================================
/* 320-576px */
//====================================================================
@media screen and (max-width: 576px) {
  .container {
    background-color: rgba(0, 217, 255, $media-color);
    width: 320px;
  }

  .prev,
  .next {
    top: 15px;
  }

  .swiper-container .swiper-slide .img-box {
    width: 315px;
    height: 250px;
    margin-left: auto;
    margin-right: 0;
  }

  .slider-upper {
    margin-top: -270px;
  }

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 25px;
  }
}
