// Setting
$font: "Geometria", sans-serif;

$white: #fff;
$black: #000;

$color-1: #cacaca;
$color-2: #191919;
$color-3: #12bcb0;
$color-4: #206ad0;
$color-5: #094b4d;

@mixin border-radius($br) {
  border-radius: $br;
  -webkit-border-radius: $br;
  -moz-border-radius: $br;
  -ms-border-radius: $br;
  -o-border-radius: $br;
}

@mixin filter-gray($fg) {
  -webkit-filter: grayscale($fg);
  -moz-filter: grayscale($fg);
  -ms-filter: grayscale($fg);
  -o-filter: grayscale($fg);
  filter: grayscale($fg);
  -webkit-filter: gray;
  filter: gray;
}

@mixin transition($tr) {
  transition: all $tr ease-in-out;
  -webkit-transition: all $tr ease-in-out;
  -moz-transition: all $tr ease-in-out;
  -ms-transition: all $tr ease-in-out;
  -o-transition: all $tr ease-in-out;
}

@mixin transform($h, $v) {
  transform: translate($h, $v);
  -webkit-transform: translate($h, $v);
  -moz-transform: translate($h, $v);
  -ms-transform: translate($h, $v);
  -o-transform: translate($h, $v);
}

@mixin scale($sc) {
  transform: scale($sc);
  -webkit-transform: scale($sc);
  -moz-transform: scale($sc);
  -ms-transform: scale($sc);
  -o-transform: scale($sc);
}

@mixin btn {
  width: 205px;
  height: 47px;
  background: linear-gradient(270deg, #12bcb0 -8.89%, $color-3 109.17%);
  border: none;
  line-height: 1;
  color: $white;
  text-align: center;
  @include border-radius(30px);

  &:hover {
    background: linear-gradient(270deg, #0c5758 -8.89%, #0b6f70 109.17%);
  }
}

@mixin words {
  position: relative;
  z-index: 1;
  font-weight: 900;
  background: linear-gradient(180deg, $color-3 10%, #fabf0e00 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: block;
  text-align: center;
  font-size: 18rem;
  line-height: 1;

  &::after {
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, #000000f9 0%, #ffffff00 100%);
  }
}

@mixin img {
  -o-object-fit: cover;
  object-fit: cover;
  max-width: 100%;
  width: auto;
  height: 100%;
}

@mixin textBlue {
  font-style: normal;
  background: linear-gradient(270deg, #206ad0 -8.89%, #09c5c9 109.17%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
