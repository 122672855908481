#types-of-surfing {
  #title {
    margin: auto;
    text-align: center;
    max-width: 80%;

    .wrapper::before {
      left: 50%;
      margin-left: -90px;
    }
  }
}
