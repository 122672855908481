#first-task {
  padding-top: 70px;
  padding-bottom: 130px;

  .container {
    position: relative;

    .sun {
      position: absolute;
      z-index: 0;
      top: -280px;
      left: -150px;
    }
  }

  .wrap {
    position: relative;
    z-index: 1;

    .right {
      .price-list {
        h3 {
          background: linear-gradient(270deg, #07948a -8.89%, $color-3 109.17%);
          border-radius: 25px 25px 0 0;
          -webkit-border-radius: 25px 25px 0 0;
          -moz-border-radius: 25px 25px 0 0;
          -ms-border-radius: 25px 25px 0 0;
          -o-border-radius: 25px 25px 0 0;
          text-align: center;
          line-height: 2;
          color: $color-5;
        }

        .box {
          background-color: $color-2;
          padding: 25px 35px;
          border-radius: 0 0 25px 25px;
          -webkit-border-radius: 0 0 25px 25px;
          -moz-border-radius: 0 0 25px 25px;
          -ms-border-radius: 0 0 25px 25px;
          -o-border-radius: 0 0 25px 25px;

          p {
            margin-top: 25px;

            &:nth-child(2) {
              font-weight: 700;
            }
          }

          ul {
            height: 135px;
            flex-direction: column;

            li {
              position: relative;
              margin-top: 25px;
              padding-left: 25px;
              color: $white;

              &::before {
                position: absolute;
                left: 0;
                top: 8px;
                content: "";
                display: block;
                width: 7px;
                height: 7px;
                background-color: $color-3;
                @include border-radius(50%);
              }
            }
          }

          hr {
            opacity: 0.1;
          }

          &-down {
            justify-content: space-between;

            p {
              font-size: 2rem;
              font-weight: 700;
              color: $white;
            }

            div {
              margin-top: 20px;
              margin-right: 0;

              span {
                text-align: center;
                display: block;
                margin-top: 10px;
                color: $white;
                font-size: 0.7rem;
                opacity: 0.3;
              }
            }
          }
        }
      }

      .price-min {
        margin-top: 20px;
        padding: 30px 35px;
        background-color: $color-2;
        justify-content: space-between;
        @include border-radius(25px);

        p {
          display: block;
          margin: auto 0;
        }

        span {
          color: $color-3;
          font-weight: 700;
          font-size: 1.1rem;
        }
      }
    }
  }
}
