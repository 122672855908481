#your-space {
  .container {
    position: relative;

    .on-wave {
      position: absolute;
      bottom: -540px;
      right: -100px;
    }
  }
  .wrap {
    justify-content: space-between;

    .right {
      .wrap {
        width: 510px;
        height: 840px;
        overflow: hidden;
        margin: auto;
        margin-right: 0;
        @include border-radius(25px);

        img {
          @include img;
        }
      }
    }
  }
}
